import { defineStore } from 'pinia';

export const useAppLayoutBlockVisibilityStore = defineStore(
  'appLayoutBlockVisibility',
  () => {
    const isAuthPopoverVisible = ref(true);
    const isAskQuestionBlockVisible = ref(true);
    const isMobileAppBlockVisible = ref(true);
    const isBecomeMasterBlockVisible = ref(true);
    const isCrmLinkBlockVisible = ref(true);
    return {
      isAuthPopoverVisible,
      isAskQuestionBlockVisible,
      isMobileAppBlockVisible,
      isBecomeMasterBlockVisible,
      isCrmLinkBlockVisible,
    };
  }
);
